<template>
    <div>
        <div>
            <div class="col-md-12">
                <div class="row" style="margin-top:20px">
                    <div class="col-md-6">
                        <h3 class="page-header">
                            <i class="fa fa fa-database animated bounceInDown show-info"></i>
                            Stock Transaction Status
                        </h3>
                    </div>
                    <div class="col-md-6 ">
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div class="col-md-2" style="margin-right:25px">
                                <button @click="backbtn()" class="btn btn-primary"><i class="fa fa-backward"></i>
                                    Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row mt-20 mb-20">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Product :</label>
                            </div>
                            <div class="col-md-8 p-0 ">
                                <span>{{ this.product?.category?.name }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-2">
                                <label>Model :</label>
                            </div>
                            <div class="col-md-10 p-0">
                                <span>{{ this.product?.name }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3"></div> -->
                </div>

            </div>
            <div class="col-md-12">
                <div class="row stock_section">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label>Stock from :</label>
                            </div>
                            <div class="col-md-7">
                                <input type="date" class="form-control form-cascade-control input-box-short" v-model="fromdate">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-5">
                                <label>Stock to :</label>
                            </div>
                            <div class="col-md-7">
                                <input type="date" class="form-control form-cascade-control input-box-short" v-model="todate">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 ">
                        <div class="row">
                            <div class="col-md-5">
                                <button class="btn text-white " @click="refresh()" style="background: #475c78;line-height: normal;">Go</button>
                               
                            </div>
                            <div class="col-md-7">
                                <select class="form-control form-cascade-control input-box-short" v-model="trantype" @change="chooseActivity()">
                                    <option value="0">Select</option>
                                    <option value="2">Sale</option>
                                    <option value="1">Purchase</option>
                                    <option value="5">Transfer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="filterDetail.length>0">
                <table class="table responsive ">
                    <thead>
                        <th>S.No.</th>
                        <th>VSDIGI-Id</th>
                        <th>Customer</th>
                        <th>Type</th>
                        <th>Transaction Date</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Balance</th>
                        <!-- <th>Action</th> -->
                    </thead>
                    <tbody> 
                        <tr  v-for="(item,index) in filterDetail" :key="item.id">
                            <td>{{ index+1 }}</td>
                            <td>
                                <span v-if="item.trantype==1">{{ item?.purchasetransaction?.user?.generatedid }}</span>
                                <span v-if="item.trantype==2">{{ item?.saletransaction?.user?.generatedid  }}</span>
                            </td>
                            <td>
                                <span v-if="item.trantype==1">{{ item.purchasetransaction?.user?.firmname }}</span>
                                <span v-if="item.trantype==2">{{ item.saletransaction?.user?.name }}</span>
                                <span v-if="item.trantype==5">{{ item.createstocktransaction?.entryby?.name }}</span>
                            </td>
                            <td>
                                <span v-if="item.trantype==1">Purchase</span>
                                <span v-if="item.trantype==2">Sale</span>
                                <span v-if="item.trantype==5 && item.inout==1">Transfer In</span>
                                <span v-if="item.trantype==5 && item.inout==2">Transfer Out</span>
                            </td>
                            <td>{{ moment(item.transactiondate).format('DD-MM-YYYY') }}</td>
                            <td><span v-if="item.inout==1">{{ item.quantity }}</span></td>
	                        <td><span v-if="item.inout==2">{{ item.quantity }}</span></td>
	                        <td><span>{{ item.finalquantity }}</span></td>
                            <!-- <td>
                                <button class="btn btn-danger collection-div" @click="removeStock(item)">X</button>
                            </td> -->
                        </tr>
                        
                    </tbody>
                </table>

            </div>
            <div v-else>
                <div class="col-md-12 norecord">
                    <div style="font-weight: bold;font-size: 30px;font-family: system-ui;">No record found.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Constants from '../../components/utilities/Constants.vue';
import moment from 'moment';
export default {
    mixins:[Constants],
    props:['product'],
    data() {
        return {
            fromdate:new Date().toISOString().slice(0,10),
            todate:new Date().toISOString().slice(0,10),
            moment:moment,
            trantype:0,
            allstock:[],
            filterDetail:[]
        }
    },
    computed: {
        ...mapGetters(['selectedstoreid','transactiondetails'])
    },
    mounted() {

    },
    methods: {
       
        backbtn(){
            this.$parent.viewno=1
            this.filterDetail=[]
            this.$parent.refresh()
        },
        // refresh(){
        //     let fy = this.getCurrentFinancialYear()
        //     console.log(fy)
        //     // let fulldate = date.getDate()
        //     // console.log(fulldate)

        // }
        refresh(){
            // this.trantype=0
            this.$store.commit('assignloadingstatus',1)
			let param = {selectedstore:this.selectedstoreid,productid:this.product.id,trantype:this.trantype,
				fromdate:this.fromdate,todate:this.todate,fy:this.getCurrentFinancialYear()};
            // console.log(param)
			axios.post('api/stock/details2',param)
	    		.then(response=>this.processDetailResponse(response.data))
	    		.catch(error=>this.showError(error));
		},
		processDetailResponse(data){
            // console.log("Data ",data)

			// this.$store.commit('assigndailystock',data.daily);
            this.allstock = data.stocks
            this.filterDetail = this.allstock?.filter(block=>block.trantype==1 || block.trantype==2 || block.trantype==5)
            this.$store.commit('assignloadingstatus',0)

			// this.previous = data.previous
			// this.openbalance=data.openbalance
			// this.closebalance=data.closebalance
			// this.$store.commit('assigndailycalculationstock',data.stockbalance);
			// console.log(data)
			// console.log(data.stockbalance)
			// this.$store.commit('assigndetailsview',true);
		},
        chooseActivity(){
            this.filterDetail = this.allstock
            let filterData = []
            if(this.trantype>0){
                filterData = this.filterDetail.filter(block=>block.trantype==this.trantype)
            }else {
                filterData = this.allstock
            }
            this.filterDetail = filterData
        },
        removeStock(item){
            console.log(item)
            if(confirm('Do you want to remove it.')){
                let param = {id:item?.id}
                console.log(param)
                axios.post('api/stock/row/rolledback',param)
                    .then((response)=>this.processRolledbackResponse(response.data))
                    .catch((error)=>console.log(error))
            }
        },
        processRolledbackResponse(data){
            if(data.status){
                console.log(data.status)
                this.refresh()
            }
        }
    },

}
</script>
<style>
.stock_section{
    padding: 10px;
    border: 1px solid silver;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
}
</style>